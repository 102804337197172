import { SSO } from "ft3-lib";
import queryString from "query-string";
import { useContext, useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useLocation, useNavigate } from "react-router-dom";
import { PuffLoader } from "react-spinners";

import Header from "../Header/Header";
import BlockchainContext from "../../contexts/BlockchainContext/blockchain.context";
import { setStoredAccount } from "../../utils/blockchain/account-storage";
import { AppContext } from "../../contexts/AppContext/app.context";

const Wrapper = ({ children }) => {
  const { chromia_account, setAccount, pageLoaded, is_page_loaded } =
    useContext(AppContext);
  const blockchain = useContext(BlockchainContext);
  const { pathname, search } = useLocation();
  const [tx, setTx] = useState(search && queryString.parse(search).rawTx);
  const navigate = useNavigate();

  const verifyAndSendTx = async () => {
    if (!tx) {
      return;
    }
    try {
      const sso = new SSO(blockchain);
      const [account, user] = await sso.finalizeLogin(tx);
      setStoredAccount({ user, account });
      localStorage.setItem("chromia_account", account.id.toString("hex"));
      setAccountAndPageLoaded(account);
      navigate("/", { replace: true });
    } catch (e) {
      console.error("Login error", e);
    }
  };

  const setAccountAndPageLoaded = (account) => {
    setAccount(account);
    pageLoaded(true);
  };

  const loadAccount = async (user, id) => {
    const loadedAccount = await blockchain.newSession(user).getAccountById(id);
    // const loadedAccount = new Account(storedAccount.id_.data, storedAccount.authDescriptor, storedAccount.tx.session)
    setAccountAndPageLoaded(loadedAccount);
  };

  useEffect(() => {
    let ft3_account = JSON.parse(localStorage.getItem("ft3_account"));
    const accountIdString = localStorage.getItem("chromia_account");
    if (tx && !chromia_account) {
      verifyAndSendTx();
    } else if (ft3_account && accountIdString) {
      const storedAccount = Buffer.from(
        localStorage.getItem("chromia_account"),
        "hex"
      );
      loadAccount(ft3_account.user, storedAccount);
    } else {
      setAccountAndPageLoaded(null);
    }
  }, []);

  return (
    <>
      <Header />
      {children}
      <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
        <span className="svg-icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              opacity="0.5"
              x="13"
              y="6"
              width="13"
              height="2"
              rx="1"
              transform="rotate(90 13 6)"
              fill="currentColor"
            />
            <path
              d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
              fill="currentColor"
            />
          </svg>
        </span>
      </div>
    </>
  );
};

export default Wrapper;
